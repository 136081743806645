<template>
  <div class="subaccount-auth">
    <a-breadcrumb>
      <a-breadcrumb-item @click="goSubaccountPage">
        <router-link :to="{name: 'System_Sub_Account'}">子账号管理</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>数据库授权（{{$route.query.name}}）</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content">
      <div class="left">
        <cc-schema-tree-select :handle-data-processing="handleDataProcessing" type="auth" :ds-auth-kind="dsAuthKind"
                               :get-user-ds-auth="getUserDsAuth"/>
      </div>
      <div class="right">
        <div class="query">
          <a-input size="small" style="width: 240px;margin-right: 8px;" v-model="currentQueryForm.text"/>
          <a-button size="small" type="primary" @click="handleQuery">查询</a-button>
        </div>
        <div class="table">
          <a-table :columns="columns" size="small" :data-source="searchCurrentTables" :rowKey="record=>record.tableName"
                   :pagination="{pageSize}" @change="handleTableChange" :loading="loading" bordered>
            <template #duration="record">
              <div v-if="record.authArr&&record.authArr.length">{{renderDurationTime(record)}}</div>
            </template>
            <template slot="name" slot-scope="record">
              {{ record.tableName }}
            </template>
            <template slot="checkAll-title">
              选中全部
              <a-checkbox @change="handleCheckAll"
                          :disabled="disableAllAuth" v-if="disableAllAuth"
              />
              <a-checkbox v-else v-model="checkAll" @change="handleCheckAll"/>
            </template>
            <template slot="checkAll" slot-scope="record">
              <a-checkbox v-if="disableAllAuth"
                          @change="handleCheckRowAll(record, $event)"
                          :disabled="disableAllAuth"
              />
              <a-checkbox v-else
                          v-model="checkRowAllObj[record.key].check"
                          @change="handleCheckRowAll(record, $event)"
                          :disabled="disableAllAuth"
              />
            </template>
            <template v-for="auth in dsAuthKind" :slot="`${auth.kindValue}-title`">
              <div :key="auth.kindValue">
                <!--              <a-popover v-if="disableAuth(auth.kindValue)" placement="right">-->
                <!--                <div slot="content">-->
                <!--                  <div v-if="instanceAuth.dsAuthKinds && instanceAuth.dsAuthKinds.includes(auth.kindValue)">-->
                <!--                    已在实例中授权-->
                <!--                  </div>-->
                <!--                  <div v-else-if="dsAuth.dsAuthKinds && dsAuth.dsAuthKinds.includes(auth.kindValue)">-->
                <!--                    已在库中授权-->
                <!--                  </div>-->
                <!--                  <div v-else-if="schemaAuth.dsAuthKinds && schemaAuth.dsAuthKinds.includes(auth.kindValue)">-->
                <!--                    已在schema中授权-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                {{ auth.kindNameI18n }}-->
                <!--                <a-checkbox @change="handleCheckColumnAll(auth.kindValue, $event)"-->
                <!--                            :disabled="disableAuth(auth.kindValue)"-->
                <!--                />-->
                <!--              </a-popover>-->
                <div>
                  {{ auth.kindNameI18n }}
                  <a-checkbox @change="handleCheckColumnAll(auth.kindValue, $event)"
                              v-model="checkColumnAllObj[auth.kindValue].check"
                              :disabled="disableAuth(auth.kindValue)"
                  />
                </div>
              </div>
            </template>
            <template v-for="auth in dsAuthKind" :slot="auth.kindValue" slot-scope="record">
              <div :key="auth.kindValue"
                   :style="`width: 100%;height: 100%;
                   background: ${rawCurrentTablesObj[record.key][auth.kindValue] === record[auth.kindValue] ? 'transparent' : '#FFF7DB'};
                   outline: 6px solid ${rawCurrentTablesObj[record.key][auth.kindValue] === record[auth.kindValue] ? 'transparent' : '#FFF7DB'}`">
                <a-popover v-if="disableAuth(auth.kindValue)" placement="right">
                  <div slot="content">
                    <div v-if="instanceAuth.dsAuthKinds && instanceAuth.dsAuthKinds.includes(auth.kindValue)">
                      已在实例中授权 {{renderDurationTime(instanceAuth)}}
                    </div>
                    <div v-else-if="dsAuth.dsAuthKinds && dsAuth.dsAuthKinds.includes(auth.kindValue)">
                      已在库中授权 {{renderDurationTime(dsAuth)}}
                    </div>
                    <div v-else-if="schemaAuth.dsAuthKinds && schemaAuth.dsAuthKinds.includes(auth.kindValue)">
                      已在schema中授权 {{renderDurationTime(schemaAuth)}}
                    </div>
                  </div>
                  <a-checkbox
                    :disabled="disableAuth(auth.kindValue)"
                    @change="handleCheckCell(record, auth.kindValue, $event)"
                    :default-checked="disableAuth(auth.kindValue)"/>
                </a-popover>
                <a-checkbox v-else v-model="record[auth.kindValue]" :key="auth.kindValue"
                            :disabled="disableAuth(auth.kindValue)"
                            @change="handleCheckCell(record, auth.kindValue, $event)"/>
              </div>

            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button type="primary" style="width: 120px;margin-right: 16px;" @click="showIfAuthConfirmModal">预览并保存</a-button>
      <a-button style="width: 120px;"><router-link :to="{name: 'System_Sub_Account'}">取消</router-link></a-button>
    </div>
    <auth-confirm-modal :visible="showAuthConfirmModal" :columns="columns" :handle-close-modal="hideAuthConfirmModal"
                        :handle-confirm="handleChangeAuthority" :ds-auth-kind="dsAuthKind"
                        :auth-list="{...addAuthTables, ...updateAuthTables, ...deleteAuthTables}"
                        v-if="showAuthConfirmModal"/>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import AuthConfirmModal from '@views/system/subaccount/components/AuthConfirmModal.vue';
import dayjs from 'dayjs';
import { PG_GP } from '../../../consts';
import authMixin from '../../../mixins/authMixin';

// const EMPTY_ALL_OBJ = {
//   check: false,
//   num: 0,
//   size: 0
// };

export default {
  name: 'Auth',
  components: { AuthConfirmModal },
  mixins: [authMixin],
  computed: {
    columns() {
      // const { text } = this.currentQuery;
      const dsColumns = [{
        title: '表',
        scopedSlots: { customRender: 'name' },
        key: 'name'
        // filteredValue: [text] || null,
        // onFilter: (value, record) => record.key.includes(value)
      }];
      this.dsAuthKind.forEach((auth) => {
        dsColumns.push({
          slots: { title: `${auth.kindValue}-title` },
          scopedSlots: { customRender: auth.kindValue },
          align: 'center'
        });
      });

      dsColumns.push({
        slots: { title: 'checkAll-title' },
        scopedSlots: { customRender: 'checkAll' },
        align: 'center'
      });

      dsColumns.push({
        title: '生效时间',
        scopedSlots: { customRender: 'duration' }
      });

      return dsColumns;
    },
    disableAllAuth() {
      return (this.instanceAuth.dsAuthKinds && this.instanceAuth.dsAuthKinds.length > 0)
        || (this.dsAuth.dsAuthKinds && this.dsAuth.dsAuthKinds.length > 0) || (this.schemaAuth.dsAuthKinds && this.schemaAuth.dsAuthKinds.length > 0);
    }
  },
  data() {
    return {
      checkAll: false,
      checkColumnAllObj: {},
      checkRowAllObj: {},
      pageSize: 20,
      current: 1,
      showAuthConfirmModal: false,
      currentQueryForm: {
        text: ''
      },
      currentQuery: {
        text: ''
      },
      selectedTreeNode: {},
      testTreeData: [],
      currentTableSize: 20,
      searchCurrentTables: [],
      loading: false,
      currentTables: [],
      currentPageTables: [],
      rawCurrentTables: [],
      rawCurrentTablesObj: {},
      dsAuthKind: [],
      userDsAuthList: {},
      userDsAuthListMap: {},
      currentDsAuthListMap: {},
      instanceAuth: {},
      dsAuth: {},
      schemaAuth: {},
      addAuthTables: {},
      updateAuthTables: {},
      deleteAuthTables: {},
      authedTablesMap: {} // 当前页面操作过的表的集合
    };
  },
  methods: {
    disableAuth(type) {
      return !!(this.instanceAuth.dsAuthKinds && this.instanceAuth.dsAuthKinds.includes(type))
        || !!(this.dsAuth.dsAuthKinds && this.dsAuth.dsAuthKinds.includes(type)) || !!(this.schemaAuth.dsAuthKinds && this.schemaAuth.dsAuthKinds.includes(type));
    },
    diff() {
      this.currentTables.forEach((table) => {
        const { key } = table;
        delete this.addAuthTables[key];
        delete this.updateAuthTables[key];
        delete this.deleteAuthTables[key];
        const preTable = this.rawCurrentTablesObj[table.key];
        const currentAuthArr = [];
        const preAuthArr = [];
        this.dsAuthKind.forEach((auth) => {
          if (table[auth.kindValue]) {
            currentAuthArr.push(auth.kindValue);
          }
          if (preTable[auth.kindValue]) {
            preAuthArr.push(auth.kindValue);
          }
        });
        if (!isEqual(preAuthArr, currentAuthArr)) {
          table.intersection = currentAuthArr.filter((v) => preAuthArr.includes(v));
          table.preAuthArr = preAuthArr;
          table.currentAuthArr = currentAuthArr;
          if (preAuthArr.length === 0) {
            this.addAuthTables[key] = table;
          } else if (currentAuthArr.length === 0) {
            this.deleteAuthTables[key] = table;
          } else {
            this.updateAuthTables[key] = table;
          }
        }
      });
    },
    isCheckAll() {
      let checkAll = true;
      Object.values(this.checkColumnAllObj).forEach((column) => {
        if (!column.check) {
          checkAll = false;
        }
      });

      this.checkAll = checkAll;
      this.$forceUpdate();
    },
    handleCheckAll(e) {
      for (let i = (this.current - 1) * this.pageSize; i < this.current * this.pageSize; i++) {
        const table = this.searchCurrentTables[i];
        if (table) {
          this.handleCheckRowAll(table, e);
        }
      }
    },
    handleCheckRowAll(row, e) {
      const { checked } = e.target;
      for (let i = 0; i < this.searchCurrentTables.length; i++) {
        const table = this.searchCurrentTables[i];
        if (table) {
          if (table.key === row.key) {
            this.dsAuthKind.forEach((auth) => {
              const { kindValue } = auth;
              const preChecked = table[kindValue];
              table[kindValue] = checked;

              if (preChecked && !checked) {
                this.checkColumnAllObj[kindValue].size -= 1;
              }

              if (!preChecked && checked) {
                this.checkColumnAllObj[kindValue].size += 1;
              }

              this.checkColumnAllObj[kindValue].check = this.checkColumnAllObj[kindValue].size && this.checkColumnAllObj[kindValue].size === this.checkColumnAllObj[kindValue].num;
            });

            this.checkRowAllObj[row.key].size = checked ? this.dsAuthKind.length : 0;
            this.checkRowAllObj[row.key].check = this.checkRowAllObj[row.key].size === this.checkRowAllObj[row.key].num;
            break;
          }
        }
      }

      this.searchCurrentTables = [...this.searchCurrentTables];
      this.isCheckAll();
      //
      // this.checkColumnAllObj = { ...this.checkColumnAllObj };
      // this.checkRowAllObj = { ...this.checkRowAllObj };
      // this.checkAllObj = { ...this.checkAllObj };
    },
    handleTableChange(pagination) {
      console.log('table change');
      const { current, pageSize } = pagination;
      this.current = current;
      this.pageSize = pageSize;

      const obj = {};
      let num = 0;
      for (let i = (current - 1) * pageSize; i < current * pageSize; i++) {
        const table = this.currentTables[i];
        if (table) {
          num++;
          this.dsAuthKind.forEach((auth) => {
            if (table[auth.kindValue]) {
              if (obj[auth.kindValue]) {
                obj[auth.kindValue] += 1;
              } else {
                obj[auth.kindValue] = 1;
              }
            }
          });
        }
      }

      this.currentTableSize = num;
      this.dsAuthKind.forEach((auth) => {
        this.checkColumnAllObj[auth.kindValue] = {
          num,
          size: obj[auth.kindValue] || 0,
          check: num && (obj[auth.kindValue] === num)
        };
      });

      this.checkColumnAllObj = { ...this.checkColumnAllObj };
      this.isCheckAll();
    },
    handleCheckColumnAll(type, e) {
      const { checked } = e.target;

      for (let i = (this.current - 1) * this.pageSize; i < (this.current) * this.pageSize; i++) {
        const table = this.searchCurrentTables[i];
        if (table) {
          table[type] = checked;
          const { key } = table;
          this.checkColumnAllObj[type].size = checked ? this.currentTableSize : 0;
          this.checkColumnAllObj[type].check = !!this.checkColumnAllObj[type].size && this.checkColumnAllObj[type].size === this.checkColumnAllObj[type].num;
          this.checkRowAllObj[key].size += checked ? 1 : -1;
          this.checkRowAllObj[key].check = this.checkRowAllObj[key].size === this.checkRowAllObj[key].num;
        }
      }

      this.isCheckAll();
      //
      // this.currentTables = [...this.currentTables];
    },
    showIfAuthConfirmModal() {
      this.diff();
      this.showAuthConfirmModal = true;
    },
    hideAuthConfirmModal() {
      this.showAuthConfirmModal = false;
    },
    handleQuery() {
      this.current = 1;
      const { text } = this.currentQueryForm;
      this.searchCurrentTables = this.currentTables.filter((table) => table.tableName.includes(text));
      const obj = {};
      this.dsAuthKind.forEach((auth) => {
        obj[auth.kindValue] = { check: false, size: 0 };
      });

      let num = 0;
      for (let i = 0; i < this.pageSize; i++) {
        const table = this.searchCurrentTables[i];
        if (table) {
          num++;
          this.dsAuthKind.forEach((auth) => {
            if (table[auth.kindValue]) {
              if (obj[auth.kindValue].size) {
                obj[auth.kindValue].size += 1;
              } else {
                obj[auth.kindValue].size = 1;
              }
            }
          });
        }
      }

      Object.keys(obj).forEach((o) => {
        obj[o].num = num;
        if (obj[o].size === num && num) {
          obj[o].check = true;
        }
      });

      this.checkColumnAllObj = obj;
      this.currentTableSize = num;
    },
    goSubaccountPage() {
      this.$router.push({ name: 'System_Sub_Account' });
    },
    async handleChangeAuthority(startTime, endTime) {
      const dsOpsAuthList = [];
      const deletes = [];
      const updates = [];

      Object.values(this.addAuthTables).forEach((ds) => {
        const addDs = {
          dataSourceId: ds.id,
          levelElements: ds.key.split('/')
            .slice(2),
          dsAuthKinds: []
        };

        const addOperateDs = cloneDeep(addDs);
        this.dsAuthKind.forEach((auth) => {
          if (ds[auth.kindValue]) {
            addOperateDs.dsAuthKinds.push(auth.kindValue);
          }
        });

        dsOpsAuthList.push(addOperateDs);
      });

      Object.values(this.updateAuthTables).forEach((ds) => {
        const newAuth = [];
        this.dsAuthKind.forEach((auth) => {
          if (ds[auth.kindValue]) {
            newAuth.push(auth.kindValue);
          }
        });
        const updateDs = {
          dataSourceId: ds.dataSourceId,
          dsAuthId: ds.id,
          dsAuthKinds: newAuth
        };
        updates.push(updateDs);
      });

      Object.values(this.deleteAuthTables).forEach((ds) => {
        deletes.push({
          dataSourceId: ds.dataSourceId,
          dsAuthId: ds.id
        });
      });

      const data = {
        dsOpsAuthList,
        updates,
        deletes,
        authedUid: this.$route.params.uid,
        startTime: null,
        endTime: null
      };

      if (startTime) {
        data.startTime = dayjs(startTime).valueOf();
      }

      if (endTime) {
        data.endTime = dayjs(endTime).valueOf();
      }

      const res = await this.$services.modifyUserDsAuth({
        data,
        msg: '修改权限成功'
      });
      if (res.success) {
        this.showAuthConfirmModal = false;
      }
    },
    handleCheckCell(table, type = '', e = null) {
      const {
        key
      } = table;
      const { checked } = e.target;
      this.checkColumnAllObj[type].size += checked ? 1 : -1;
      this.checkColumnAllObj[type].check = !!this.checkColumnAllObj[type].size && this.checkColumnAllObj[type].size === this.checkColumnAllObj[type].num;

      this.checkRowAllObj[key].size += table[type] ? 1 : -1;
      this.checkRowAllObj[key].check = this.checkRowAllObj[key].size === this.checkRowAllObj[key].num;

      this.isCheckAll();
    },
    async getDsAuthKind() {
      const res = await this.$services.listAllDsAuthKind();
      if (res.success) {
        this.dsAuthKind = res.data;
        const obj = {};
        res.data.forEach((auth) => {
          obj[auth.kindValue] = { check: false };
        });
        this.checkColumnAllObj = obj;
      }
    },
    async getUserDsAuth(dataSource, levelElements) {
      const res = await this.$services.listUserDsAuth({
        data: {
          dataSourceId: dataSource.id,
          levelElements: levelElements.length ? levelElements : [''],
          ownerUid: this.$route.params.uid
        }
      });
      if (res.success) {
        const len = PG_GP.includes(dataSource.dataSourceType) ? 5 : 4;
        this.userDsAuthList = res.data;
        const userDsAuthListMap = {};
        const currentDsAuthListMap = {};
        let instanceAuth = {};
        let dsAuth = {};
        let schemaAuth = {};
        res.data.forEach((auth) => {
          if (auth.path && auth.path !== '/' && auth.path[auth.path.length - 1] === '/') {
            auth.path = auth.path.substr(0, auth.path.length - 1);
          }
        });
        res.data.forEach((schema) => {
          const {
            dsAuthKinds,
            path,
            dsEnv,
            dsDesc,
            instanceId
          } = schema;
          const key = path === '/' ? `${dsEnv}/${instanceId}` : `${dsEnv}/${instanceId}${path}`;
          const keyLen = key.split('/').length;
          if (keyLen < len) {
            if (keyLen === 2) {
              instanceAuth = schema;
            } else if (keyLen === 3) {
              dsAuth = schema;
            } else if (keyLen === 4) {
              schemaAuth = schema;
            }
          } else {
            currentDsAuthListMap[key] = schema;
          }
          userDsAuthListMap[key] = schema;
          dsAuthKinds.forEach((auth) => {
            schema[auth] = true;
          });
          schema.title = path === '/' ? dsDesc : path.split('/')[1];
          schema.key = key;
        });
        this.userDsAuthListMap = userDsAuthListMap;
        this.currentDsAuthListMap = currentDsAuthListMap;
        this.instanceAuth = instanceAuth;
        this.dsAuth = dsAuth;
        this.schemaAuth = schemaAuth;
      }
    },
    async handleDataProcessing(data, datasource) {
      this.loading = true;
      this.diff();
      const currentTables = [];
      this.current = 1;
      const rawCurrentTablesObj = {};
      await this.getUserDsAuth(datasource, datasource.key.split('/').slice(2));

      const authObj = {};
      this.dsAuthKind.forEach((auth) => {
        authObj[auth.kindValue] = false;
      });
      data.forEach((t) => {
        const tableName = t.name;
        const key = `${datasource.key}/${tableName}`;
        let currentTable = {
          tableName,
          ...datasource,
          ...authObj,
          key
        };

        if (this.userDsAuthListMap[key]) {
          currentTable = { ...currentTable, ...this.userDsAuthListMap[key] };
        }

        rawCurrentTablesObj[key] = cloneDeep(currentTable);

        if (this.addAuthTables[key]) {
          currentTable = { ...currentTable, ...this.addAuthTables[key] };
        }

        if (this.updateAuthTables[key]) {
          currentTable = { ...currentTable, ...this.updateAuthTables[key] };
        }

        if (this.deleteAuthTables[key]) {
          currentTable = { ...currentTable, ...this.deleteAuthTables[key] };
        }

        const table = {
          ...currentTable,
          authArr: [],
          key
        };

        const len = this.dsAuthKind.length;
        let authNum = 0;

        this.dsAuthKind.forEach((auth) => {
          if (table[auth.kindValue]) {
            table.authArr.push(auth.kindValue);
            authNum += 1;
          }
        });

        this.checkRowAllObj[key] = { num: len, size: authNum, check: authNum === len };
        // rawCurrentTablesObj[key] = cloneDeep(table);
        currentTables.push(table);
      });
      this.currentTables = cloneDeep(currentTables);
      this.handleQuery();
      this.rawCurrentTables = cloneDeep(currentTables);
      this.rawCurrentTablesObj = rawCurrentTablesObj;
      this.checkRowAllObj = { ...this.checkRowAllObj };
      this.isCheckAll();
      this.loading = false;
    }
  },
  async created() {
    await this.getDsAuthKind();
  }
};
</script>

<style lang="less">
.subaccount-auth {
  .content {
    margin-top: 10px;
    width: 100%;
    display: flex;

    .left {
    }

    .right {
      flex: 1;
      border: 1px solid rgba(199, 199, 199, 1);
      border-left: none;

      .query {
        padding-left: 10px;
        height: 44px;
        line-height: 44px;
        background: #FAFAFA;
      }

      .table {
        height: calc(~"100vh - 235px");
        overflow: scroll;
      }
    }
  }

  .footer {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
